<script lang="ts" setup>
import { LocaleCode, useAssetOnLocale } from '~/locales'
import { client } from '@/utils'

const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('nbZ4Ekjr6u1KfyeyKpU0Q')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('u5nY6m2Fza99JTaNrG9gV')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})

// pc
const pcimg0 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer0-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer0-en.png?url'),
})
const pcimg1 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer1-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer1-en.png?url'),
})
const pcimg2 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer2-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer2-en.png?url'),
})
const pcimg3 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer3-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer3-en.png?url'),
})
const pcimg4 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer4-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer4-en.png?url'),
})
const pcimg5 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer5-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer5-en.png?url'),
})
const pcimg6 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer6-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer6-en.png?url'),
})
const pcimg7 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer7-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer7-en.png?url'),
})
const pcimg8 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer8-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer8-en.png?url'),
})
const pcimg9 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer9-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer9-en.png?url'),
})
const pcimg10 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer10-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer10-en.png?url'),
})
const pcimg11 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/faq4answer11-zh.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/faq4answer11-en.png?url'),
})

// mobile
const mbimg0 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer0mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer0mben.png?url'),
})
const mbimg1 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer1mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer1mben.png?url'),
})
const mbimg2 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer2mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer2mben.png?url'),
})
const mbimg3 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer3mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer3mben.png?url'),
})
const mbimg4 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer4mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer4mben.png?url'),
})
const mbimg5 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer5mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer5mben.png?url'),
})
const mbimg6 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer6mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer6mben.png?url'),
})
const mbimg7 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer7mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer7mben.png?url'),
})
const mbimg8 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer8mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer8mben.png?url'),
})
const mbimg9 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer9mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer9mben.png?url'),
})
const mbimg10 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer10mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer10mben.png?url'),
})
const mbimg11 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer11mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer11mben.png?url'),
})
const mbimg12 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer12mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer12mben.png?url'),
})
const mbimg13 = useAssetOnLocale({
  [LocaleCode.ZH]: import('~/assets/imgs/help/mb/zh/faq4answer13mb.png?url'),
  [LocaleCode.EN]: import('~/assets/imgs/help/mb/en/faq4answer13mben.png?url'),
})
</script>

<template>
  <article class="feq-root">
    <div v-if="client.isPc" class="feq-content page-content-width">
      <h1>{{ $t('nbZ4Ekjr6u1KfyeyKpU0Q') }}</h1>
      <p>
        {{ $t('u5nY6m2Fza99JTaNrG9gV') }}
        {{ $t('nKZbK0d0IwfshvWl2uSkc') }}
      </p>
      <h2>
        {{ $t('uukocg6kofzIpSj0zKaD') }}
      </h2>
      <p>{{ $t('_-Mr_z8u7P2y9v8WMz9Dg') }}</p>
      <img :src="pcimg0" alt="">
      <br>
      <p>{{ $t('50Jo2Jfic0NPy8k071RYI') }}</p>
      <img :src="pcimg1" alt="">
      <br>
      <p>{{ $t('5URLYJevJ5UMcqNUliwcZ') }}</p>
      <img :src="pcimg2" alt="">
      <br>
      <p>{{ $t('nlrO93EAJ7F7nQiDVxrvH') }}</p>
      <img :src="pcimg3" alt="">
      <br>
      <p>{{ $t('tL3HAKofDkrLYCZXwCRqs') }}</p>
      <img :src="pcimg4" alt="">
      <br>
      <p>{{ $t('35E1fcSPwsvtMNGUf4w2d') }}</p>
      <img :src="pcimg5" alt="">
      <img :src="pcimg6" alt="">
      <br>
      <p>{{ $t('hzGqbYy_56mvT8HF2Bm') }}</p>
      <img :src="pcimg7" alt="">
      <br>
      <h2>{{ $t('a0ZnhXohdvtgQx_kJFgSV') }}</h2>
      <p>{{ $t('nzn7Mu-dIwmduW2zlpkkx') }}</p>
      <img :src="pcimg8" alt="">
      <br>
      <p>{{ $t('gGkDXke1DJPlhFiakP0kn') }}</p>
      <img :src="pcimg9" alt="">
      <p>{{ $t('SpRXuNz2yeUs3KooKJijn') }}</p>
      <img :src="pcimg10" alt="">
      <br>
      <p>{{ $t('UFjOMk2v82LKqfu85sES7') }}</p>
      <img :src="pcimg11" alt="">
    </div>
    <div v-else class="feq-content">
      <h1>{{ $t('nbZ4Ekjr6u1KfyeyKpU0Q') }}</h1>
      <p>
        {{ $t('u5nY6m2Fza99JTaNrG9gV') }}
      </p>

      <h2>{{ $t('opiG6BV33BO3rgXi9R1xg') }}</h2>
      <p>{{ $t('F3IImI5nH1P1IEx30-SHE') }}</p>
      <img :src="mbimg0" alt="">
      <br>
      <p>{{ $t('beCMYEqHdwjU7vceBitWP') }}</p>
      <img :src="mbimg1" alt="">
      <br>
      <p>{{ $t('3EmSfC354XLFG6oLsmdae') }}</p>
      <img :src="mbimg2" alt="">
      <p>{{ $t('l8b06CQbqcHtw88CTWrr8') }}</p>
      <img :src="mbimg3" alt="">
      <br>
      <p>{{ $t('avF6iAtr9Z8Ks2jpOhc5f') }}</p>
      <img :src="mbimg4" alt="">
      <br>
      <p>{{ $t('oXNs95jjhfFelmk1kXVrV') }}</p>
      <img :src="mbimg5" alt="">
      <br>
      <p>{{ $t('W8bTVIOiquBzm0n8oVQVx') }}</p>
      <img :src="mbimg6" alt="">
      <br>
      <p>{{ $t('mMAtCU-4Ym9EHtHIKTsE2') }}</p>
      <img :src="mbimg7" alt="">
      <br>
      <p>{{ $t('_xjK0K6xsVdzybj_JfcQ8') }}</p>
      <img :src="mbimg8" alt="">
      <br>
      <h2>{{ $t('a0ZnhXohdvtgQx_kJFgSV') }}</h2>
      <p>{{ $t('N65CJNWasCtxjJ-Mjvf7W') }}</p>
      <img :src="mbimg9" alt="">
      <br>
      <p>{{ $t('7ZQFlHV0ypYGfYA8hOfPX') }}</p>
      <img :src="mbimg10" alt="">
      <p>{{ $t('QHmPWse7nOxXrEGBGW8MU') }}</p>
      <img :src="mbimg11" alt="">
      <br>
      <p>{{ $t('kNqvP4viPQjw_mg0j8WqJ') }}</p>
      <img :src="mbimg12" alt="">
      <br>
      <p>{{ $t('Tq-dILzlztK2E4PxrXJXX') }}</p>
      <img :src="mbimg13" alt="">
      <br>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
